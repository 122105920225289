import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import $ from 'jquery';

import { API_URL, DATATABLES_DEFAULT_CONFIG } from '../../../constants/env';
import Utils from '../../../helpers/utils';

export default function MemberTransactionLog() {
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { filterOutlet, filterDate } = useSelector((state) => state.memberFilter);

  useEffect(() => {
    initializeDataTables();
  }, [filterOutlet, filterDate]);

  const initializeDataTables = () => {
    $('#transactionLogDataTable').DataTable().destroy();
    $('#transactionLogDataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      ajax: {
        url: `${API_URL}/member-transaction?source=datatables&o=${filterOutlet?.id}&s=${filterDate.startDate}&e=${filterDate.endDate}`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        { data: 'outlet_name' },
        { data: 'time' },
        { data: 'member.card_no', name: 'member.card_no' },
        { data: 'member.name', name: 'member.name' },
        { data: 'debit', render: (data) => (Number(data) > 0 ? 'TOPUP' : 'TRANSACTION') },
        { data: 'description' },
        { data: 'debit', render: (data) => Utils.numberFormat(data) },
        { data: 'credit', render: (data) => Utils.numberFormat(data) },
        { data: 'payment_method_name' },
      ],
      columnDefs: [
        { className: 'dt-head-center dt-body-right', targets: [5, 6] },
        { className: 'dt-head-center dt-body-center', targets: [7] },
      ],
    });
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Transaction Log</h3>
          </div>
        </div>

        {/* Table Content */}
        <div className="block w-full overflow-x-auto p-6">
          {/* Projects table */}
          <table
            id="transactionLogDataTable"
            className="items-center min-w-full w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Outlet
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Time
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Card No
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Member
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Type
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Description
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Debit
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Credit
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Payment
                </th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </>
  );
}
